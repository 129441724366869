import React, { useState } from 'react';
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as formik from 'formik';
import * as yup from 'yup';

const SignInForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const { Formik } = formik;

  const schema = yup.object().shape({
    email: yup.string().email('Invalid email!').required('Email is required!'),
    password: yup.string().required('Password is required')
  });

  const navigate = useNavigate();

  const onSubmit = async (values: any) => {
    const formData = values;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/login`,
        formData
      );

      if (response.data['status'] == false) {
        toast.error(response.data['error']['message']);
        return false;
      }

      localStorage.setItem(
        'user',
        JSON.stringify(response.data['data']['user'])
      );
      localStorage.setItem(
        'refreshToken',
        response.data['data']['refreshToken']
      );
      localStorage.setItem('token', response.data['data']['token']);
      localStorage.setItem('isAuthenticated', 'true');
      navigate(`/${response.data['data']['user'].role}`);
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Sign In</h3>
      </div>
      <Formik
        validationSchema={schema}
        onSubmit={values => onSubmit(values)}
        initialValues={{
          email: '',
          password: ''
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="email">Email address</Form.Label>
              <div className="form-icon-container">
                <Form.Control
                  id="email"
                  type="email"
                  name="email"
                  className="form-icon-input"
                  placeholder="name@example.com"
                  value={values.email}
                  onChange={handleChange}
                  isValid={touched.email && !errors.email}
                  isInvalid={touched.email && !!errors.email}
                />
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-body fs-9 form-icon"
                />
                <Form.Control.Feedback type="invalid">
                  {' '}
                  {errors.email}{' '}
                </Form.Control.Feedback>
              </div>
            </Form.Group>

            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="password">Password</Form.Label>
              <div className="form-icon-container">
                <Form.Control
                  id="password"
                  type="password"
                  name="password"
                  className="form-icon-input"
                  placeholder="Password"
                  value={values.password}
                  onChange={handleChange}
                  isValid={touched.password && !errors.password}
                  isInvalid={touched.password && !!errors.password}
                />
                <FontAwesomeIcon
                  icon={faKey}
                  className="text-body fs-9 form-icon"
                />
                <Form.Control.Feedback type="invalid">
                  {' '}
                  {errors.password}{' '}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Row className="flex-between-center mb-7">
              <Col xs="auto">
                <Form.Check type="checkbox" className="mb-0">
                  <Form.Check.Input
                    type="checkbox"
                    name="remember-me"
                    id="remember-me"
                    defaultChecked
                  />
                  <Form.Check.Label htmlFor="remember-me" className="mb-0">
                    Remember me
                  </Form.Check.Label>
                </Form.Check>
              </Col>
              <Col xs="auto">
                <Link to={`/auth/forgot-password`} className="fs-9 fw-semibold">
                  Forgot Password?
                </Link>
              </Col>
            </Row>
            <Button type="submit" variant="primary" className="w-100 mb-3">
              Sign In
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignInForm;
