import axios from 'axios';
import classNames from 'classnames';
import Button from 'components/base/Button';
import * as formik from 'formik';
import { errorHandler } from 'helpers/response-utils';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clinicValidationSchmaForUpdateAdmin } from 'static/Clinic';
import * as yup from 'yup';
import QRCode from 'qrcode';

const SAEditClinic = () => {
  const navigate = useNavigate();
  const { Formik } = formik;
  const { id } = useParams();
  const token = localStorage.getItem('token');
  const [qrCodeImage, setQrCodeImage] = useState('');
  const [clinic, setClinic] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    postcode: '',
    code: ''
  });

  const schema = yup.object().shape(clinicValidationSchmaForUpdateAdmin);

  useEffect(() => {
    getClinic();
  }, []);

  const getClinic = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/clinic/sa/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        errorHandler(response.data);
        return;
      }

      const data = response.data.data.clinic;
      setQrCodeImage(await QRCode.toDataURL(data.code));
      setClinic({
        name: data.name,
        email: data.email,
        phone: data.phone,
        postcode: data.postcode,
        address: data.address,
        code: data.code
      });
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const onSubmit = async (values: any) => {
    const data = {
      name: values.name,
      email: values.email,
      address: values.address,
      phone: values.phone,
      postcode: values.postcode
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ENDPOINT}/clinic/sa/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        toast.error(response.data.error.message);
        return;
      }

      toast(response.data.data.message);
      navigateBack();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const navigateBack = () => {
    navigate('/superadmin/clinic/list');
  };

  return (
    <Card className="col-12">
      <Card.Header>
        <div className="d-sm-flex justify-content-between">
          <h2>Edit clinic</h2>
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xl={12}>
            <Formik
              validationSchema={schema}
              onSubmit={values => onSubmit(values)}
              initialValues={clinic}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                setValues
              }) => {
                useEffect(() => {
                  setValues(clinic);
                }, [clinic]);
                return (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row className={classNames('g-3', 'mb-6')}>
                      <Col sm={6} md={6}>
                        <Form.Group className="mb-2">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={touched.name && !!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {' '}
                            {errors.name}{' '}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={6} md={6}>
                        <Form.Group className="mb-2">
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isValid={touched.email && !errors.email}
                            isInvalid={touched.email && !!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {' '}
                            {errors.email}{' '}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={6} md={6}>
                        <Form.Group className="mb-2">
                          <Form.Label>Contact</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6} md={6}>
                        <Form.Group className="mb-2">
                          <Form.Label>Postcode</Form.Label>
                          <Form.Control
                            type="text"
                            name="postcode"
                            value={values.postcode}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>

                      <Col sm={8} md={8}>
                        <Row className={classNames('g-3', 'mb-6')}>
                          <Col sm={6} md={6}>
                            <Form.Group className="mb-2">
                              <Form.Label>Code</Form.Label>
                              <Form.Control
                                type="text"
                                name="code"
                                placeholder="Code"
                                value={values.code}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3} md={3}>
                            {' '}
                            <br />
                            <Button
                              variant="outline-primary"
                              className="me-2 px-6"
                              disabled
                            >
                              Generate
                            </Button>{' '}
                          </Col>
                        </Row>
                      </Col>

                      <Col sm={3} md={3}>
                        <a href={qrCodeImage} download={values.code}>
                          <img src={qrCodeImage} alt="" className="w-50" />
                        </a>
                      </Col>

                      <Col sm={12} md={12}>
                        <Form.Group className="mb-2">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            rows={4}
                          />
                        </Form.Group>
                      </Col>

                      <div className="text-end">
                        <Button
                          variant="phoenix-primary"
                          className="me-2 px-6"
                          onClick={navigateBack}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary">
                          Update
                        </Button>
                      </div>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SAEditClinic;
