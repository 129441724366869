import { ColumnDef } from '@tanstack/react-table';
import axios from 'axios';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import { errorHandler } from 'helpers/response-utils';
import { useState } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import UpdateGoals from './UpdateGoals';

export const goalsTableColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'weight',
    header: 'Weight',
    cell: ({ row: { original } }) => {
      const { weight } = original;
      return (
        <div className="d-flex align-items-center">
          <div>{weight}</div>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-4 border-start border-end border-translucent ps-0'
      },
      cellProps: {
        className:
          'ps-4 border-start border-end border-translucent white-space-nowrap ps-0'
      }
    }
  },
  {
    accessorKey: 'HBA1C',
    header: 'HBA1C',
    cell: ({ row: { original } }) => {
      const { HBA1C } = original;
      return <>{HBA1C}</>;
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-4 border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'Date',
    header: () => {
      return (
        <div className="d-inline-flex flex-center m-2">
          <span>Date</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { date } = original;
      return <>{date}</>;
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-4 border-end border-translucent'
      }
    }
  },
  {
    id: 'leadDropdown',
    cell: ({ row: { original }, table }: any) => {
      const token = localStorage.getItem('token');
      const deleteReacod = async (id: number) => {
        const response = await axios.delete(
          `${process.env.REACT_APP_ENDPOINT}/clinic/patient/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        if (response.data['status'] == false) {
          errorHandler(response.data);
          return false;
        }
        handleClose();
      };

      const [show, setShow] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);

      const handleClose = () => setShow(false);

      const handleCloseEditModal = (value: any) => {
        if (value !== false) {
          console.log(original);

          // original = value;
        }
        setShowEditModal(false);
      };

      const handleShow = () => setShow(true);
      const handleShowEditModal = () => setShowEditModal(true);

      return (
        <>
          <RevealDropdownTrigger>
            <RevealDropdown>
              <Dropdown.Item onClick={handleShowEditModal}>Edit</Dropdown.Item>
              <Dropdown.Item onClick={handleShow} className="text-danger">
                Remove
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>

          <Modal
            show={showEditModal}
            onHide={() => handleCloseEditModal(false)}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Body>
              <UpdateGoals
                handleCloseEditModal={handleCloseEditModal}
                goalId={original.id}
              />
            </Modal.Body>
          </Modal>

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this record?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => deleteReacod(original.id)}
              >
                Yes delete
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'text-end border-end pe-4 ps-4 border-translucent'
      },
      cellProps: {
        className: 'text-end border-end pe-4 ps-4 border-translucent'
      }
    }
  }
];

const GoalsTable = () => {
  return (
    <div className="border-top border-translucent">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
      <AdvanceTableFooter pagination className="py-4" />
    </div>
  );
};

export default GoalsTable;
