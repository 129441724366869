import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Button from 'components/base/Button';
import { errorHandler } from 'helpers/response-utils';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import AddGoals from './AddGoals';
import GoalsTable, { goalsTableColumns } from './GoalsTable';

const GoalsList = () => {
  const navigate = useNavigate();
  const [goalsData, setGoalsData] = useState([]);
  const userJSON = localStorage.getItem('user');
  const token = localStorage.getItem('token');
  const { id } = useParams();
  const [show, setShow] = useState(false);

  const handleClose = (isAddedRecord: boolean) => {
    setShow(false);
    if (isAddedRecord) {
      getList();
    }
  };

  const handleShow = () => setShow(true);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/clinic/goals/patient/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.data['status'] == false) {
      errorHandler(response.data);
      return false;
    }
    setGoalsData(response.data['data']['goals']);
  };

  const table = useAdvanceTable({
    data: goalsData,
    columns: goalsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false,
    initialState: {
      columnVisibility: {}
    }
  });

  const navigateToCreateLead = () => {
    navigate('/clinic/goals/add');
  };

  return (
    <Row>
      <Col xl={12}>
        <AdvanceTableProvider {...table}>
          <Row className="g-3 justify-content-between mb-4">
            <Col xs="auto">
              <Button
                variant="primary"
                className="me-4"
                startIcon={<FontAwesomeIcon icon={faPlus} className="me-2" />}
                onClick={() => setShow(true)}
              >
                Add Goal
              </Button>
            </Col>
          </Row>
          {/* onUpdateComplete={getList} */}
          <GoalsTable />
        </AdvanceTableProvider>
        <Modal
          show={show}
          onHide={() => handleClose(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Add Goal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddGoals handleClose={handleClose} />
          </Modal.Body>
        </Modal>
      </Col>
    </Row>
  );
};

export default GoalsList;
