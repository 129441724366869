import StaticPageLayout from 'layouts/StaticPageLayout';
import React, { useState } from 'react';
import { faKey, faNoteSticky, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as formik from 'formik';
import * as yup from 'yup';

const ContactUs = () => {
  const { Formik } = formik;

  const schema = yup.object().shape({
    email: yup.string().email('Invalid email!').required('Email is required!'),
    subject: yup
      .string()
      .min(4, 'Subject is Too Short')
      .max(30, 'Subject is Too Long')
      .required('Subject is required'),
    body: yup
      .string()
      .min(20, 'Message body is Too Short')
      .max(300, 'Message body is Too Long')
      .required('Message body is required')
  });

  const onSubmit = (values: any) => {};

  return (
    <StaticPageLayout>
      <div className="col-6">
        <div className="text-center mb-7">
          <h3 className="text-body-highlight">Contact us</h3>
        </div>
        <Formik
          validationSchema={schema}
          onSubmit={values => onSubmit(values)}
          initialValues={{
            email: '',
            subject: '',
            body: ''
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3 text-start">
                <Form.Label htmlFor="email">Email address</Form.Label>
                <div className="form-icon-container">
                  <Form.Control
                    id="email"
                    type="email"
                    name="email"
                    className="form-icon-input"
                    placeholder="name@example.com"
                    value={values.email}
                    onChange={handleChange}
                    isValid={touched.email && !errors.email}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <FontAwesomeIcon
                    icon={faUser}
                    className="text-body fs-9 form-icon"
                  />
                  <Form.Control.Feedback type="invalid">
                    {' '}
                    {errors.email}{' '}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              <Form.Group className="mb-3 text-start">
                <Form.Label htmlFor="subject">Subject</Form.Label>
                <div className="form-icon-container">
                  <Form.Control
                    id="subject"
                    type="text"
                    name="subject"
                    className="form-icon-input"
                    placeholder="subject"
                    value={values.subject}
                    onChange={handleChange}
                    isValid={touched.subject && !errors.subject}
                    isInvalid={touched.subject && !!errors.subject}
                  />
                  <FontAwesomeIcon
                    icon={faNoteSticky}
                    className="text-body fs-9 form-icon"
                  />
                  <Form.Control.Feedback type="invalid">
                    {' '}
                    {errors.subject}{' '}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label>Message body</Form.Label>
                <div className="form-icon-container">
                  <Form.Control
                    as="textarea"
                    name="body"
                    value={values.body}
                    onChange={handleChange}
                    rows={4}
                    isValid={touched.body && !errors.body}
                    isInvalid={touched.body && !!errors.body}
                  />
                  <Form.Control.Feedback type="invalid">
                    {' '}
                    {errors.body}{' '}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              <Button type="submit" variant="primary" className="w-100 mb-3">
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </StaticPageLayout>
  );
};

export default ContactUs;
