import * as yup from 'yup';

export const source = [
  'Organic',
  'Phone calls',
  'Google ads',
  'Leads.io',
  'Cold email',
  'Referral',
  'Others'
];

export const contactSourceData = [
  { name: 'Organic', value: 0, color: 'primary' },
  { name: 'Google ads', value: 0, color: 'success' },
  { name: 'Phone calls', value: 0, color: 'info' },
  { name: 'Leads.io', value: 0, color: 'info-light' },
  { name: 'Referral', value: 0, color: 'danger-lighter' },
  { name: 'Cold email', value: 0, color: 'info' },
  { name: 'Others', value: 0, color: 'warning-light' }
];

export const clinicValidationSchma = {
  name: yup
    .string()
    .min(4, 'Too Short Name!')
    .max(30, 'Too Long Name!')
    .required('Name is required!'),
  email: yup
    .string()
    .email('Invalid email!')
    .required('Email address is required!'),
  password: yup
    .string()
    .min(12, 'Password is too Short Name!')
    .max(12, 'Password is too Long Name!')
    .required('Password is required!'),
  code: yup
    .string()
    .min(6, 'Invalid code!')
    .max(6, 'Invalid code!')
    .required('Code is required!')
};

export const clinicValidationSchmaForUpdateAdmin = {
  name: yup
    .string()
    .min(4, 'Too Short Name!')
    .max(30, 'Too Long Name!')
    .required('Name is required!'),
  email: yup
    .string()
    .email('Invalid email!')
    .required('Email address is required!')
};

export const validationSchmaForUpdateClinic = {
  name: yup
    .string()
    .min(4, 'Too Short Name!')
    .max(30, 'Too Long Name!')
    .required('Name is required!'),
  email: yup
    .string()
    .email('Invalid email!')
    .required('Email address is required!')
};
