import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import SearchBox from 'components/common/SearchBox';
import ClinicTable, {
  ClinicTableColumns
} from 'components/tables/superadmin/ClinicTable';
import { errorHandler } from 'helpers/response-utils';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Clinics = () => {
  const nav = useNavigate();
  const [clinis, setClinis] = useState([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    getClinicList();
  }, []);

  const getClinicList = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/clinic/sa`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.data['status'] == false) {
      errorHandler(response.data);
      return false;
    }

    setClinis(response.data['data']['clinics']);
  };

  const table = useAdvanceTable({
    data: clinis,
    columns: ClinicTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const navigateToCreateClinic = () => {
    nav('/superadmin/clinic/add');
  };
  return (
    <div>
      <div className="mb-9">
        <AdvanceTableProvider {...table}>
          <h2 className="mb-4">{clinis.length} Clinics</h2>
          <Row className="g-3 justify-content-between mb-4">
            <Col xs="auto">
              <Button
                variant="primary"
                className="me-4"
                startIcon={<FontAwesomeIcon icon={faPlus} className="me-2" />}
                onClick={navigateToCreateClinic}
              >
                Add new clinic
              </Button>
            </Col>
            <Col xs="auto">
              <div className="d-flex">
                <SearchBox
                  placeholder="Search by name"
                  className="me-2"
                  onChange={handleSearchInputChange}
                />
                <DatePicker defaultValue="Mar 1, 2022" />
              </div>
            </Col>
          </Row>
          <ClinicTable />
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Clinics;
