import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import classNames from 'classnames';
import Button from 'components/base/Button';
import * as formik from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

const UploadPrescription = () => {
  const navigate = useNavigate();
  const { Formik, useFormik } = formik;
  const { id } = useParams(); // Assuming you're using React Router for navigation
  const token = localStorage.getItem('token');
  const [prescription, setPrescription] = useState();
  const [show, setShow] = useState('');

  useEffect(() => {
    getPrescription();
  }, []);

  const getPrescription = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/clinic/prescription/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        toast.error(response.data.error.message);
        return;
      }

      setPrescription(response.data.data.prescription);
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const reportsUploadForm = useFormik({
    initialValues: {
      prescriptionFile: null
    },
    validationSchema: yup.object({
      prescriptionFile: yup.mixed().required('A file is required')
    }),
    onSubmit: async (values: any) => {
      const formData = new FormData();
      formData.append('patientId', '' + id);
      formData.append('notes', '');
      formData.append('prescriptionFile', values.prescriptionFile);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/clinic/prescription`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        if (response.data.status === false) {
          toast.error(response.data.error.message);
          return;
        }

        reportsUploadForm.resetForm();
        resetFileInputs();
        getPrescription();
        toast(response.data.data.message);
      } catch (error) {
        console.error('Error occurred:', error);
      }
    }
  });

  // Refs for the file input fields
  const prescriptionInputRef = useRef<HTMLInputElement>(null);

  // Function to reset the file input fields
  const resetFileInputs = () => {
    if (prescriptionInputRef.current) prescriptionInputRef.current.value = '';
  };

  const showModal = (modalType: string) => {
    setShow(modalType);
  };

  const handleClose = () => {
    setShow('');
  };

  const handleDeleteReport = async () => {
    if (!prescription) {
      return false;
    }

    const prescriptionId = prescription['id'];

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_ENDPOINT}/clinic/prescription/${prescriptionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        toast.error(response.data.error.message);
        return;
      }
      getPrescription();
      handleClose();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const navigateBack = () => {
    navigate('/clinic/patient/list');
  };

  return (
    <>
      <Row>
        <Col xl={12}>
          <Form noValidate onSubmit={reportsUploadForm.handleSubmit}>
            <Row className={classNames('g-3', 'mb-6')}>
              <Col sm={6} md={6}>
                <Form.Group controlId="imageUpload" className="mb-3">
                  <Form.Label>Meal plan</Form.Label>
                  <input
                    type="file"
                    name="prescriptionFile"
                    className="form-control"
                    onChange={event => {
                      if (
                        event.currentTarget.files &&
                        event.currentTarget.files[0]
                      ) {
                        reportsUploadForm.setFieldValue(
                          'prescriptionFile',
                          event.currentTarget.files[0]
                        );
                      }
                    }}
                    ref={prescriptionInputRef}
                    onBlur={reportsUploadForm.handleBlur}
                  />
                  {reportsUploadForm.touched.prescriptionFile &&
                  reportsUploadForm.errors.prescriptionFile ? (
                    <Form.Control.Feedback type="invalid">
                      {' '}
                      {typeof reportsUploadForm.errors.prescriptionFile ===
                        'string' &&
                        reportsUploadForm.errors.prescriptionFile}{' '}
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
              </Col>

              <div className="text-end">
                <Button
                  variant="phoenix-primary"
                  className="me-2 px-6"
                  onClick={navigateBack}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Update
                </Button>
              </div>
            </Row>
          </Form>
        </Col>

        <Col xl={12}>
          <Row className={classNames('g-3', 'mb-6')}>
            {prescription && prescription['prescriptionFile'] ? (
              <Col sm={4} md={4}>
                <Card style={{ width: '20rem' }}>
                  <Card.Body>
                    <Card.Title>Suggested diet</Card.Title>
                    <Card.Text>
                      <a href={prescription['prescriptionFile']} download>
                        {JSON.parse(prescription['fileMeta']).name}
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        style={{ cursor: 'pointer' }}
                        onClick={() => showModal('medical')}
                      />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
      <Modal
        show={show != ''}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete record?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDeleteReport}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadPrescription;
