import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';

export const nutritionTableColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'name',
    header: 'Food',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return <>{name}</>;
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-3 pe-3 border-start border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-3 border-start border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'Carbohydrate',
    header: 'Carbohydrate',
    cell: ({ row: { original } }) => {
      const nutritionFact = original?.nutritionFact;
      const res = nutritionFact?.find(
        (item: any) => item.nutrientName == 'Carbohydrate, by difference'
      );
      return (
        <>
          {res?.value} {res?.unitName}
        </>
      );
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-3 pe-3 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-3 border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'Protein',
    header: 'Protein',
    cell: ({ row: { original } }) => {
      const nutritionFact = original?.nutritionFact;
      const res = nutritionFact?.find(
        (item: any) => item.nutrientName == 'Protein'
      );
      return (
        <>
          {res?.value} {res?.unitName}
        </>
      );
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-3 pe-3 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-3 border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'Sugars',
    header: 'Sugars',
    cell: ({ row: { original } }) => {
      const nutritionFact = original?.nutritionFact;
      const res = nutritionFact?.find(
        (item: any) => item.nutrientName == 'Total Sugars'
      );
      return (
        <>
          {res?.value} {res?.unitName}
        </>
      );
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-3 pe-3 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-3 border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'Total lipid (fat)',
    header: 'Total (fat)',
    cell: ({ row: { original } }) => {
      const nutritionFact = original?.nutritionFact;
      const res = nutritionFact?.find(
        (item: any) => item.nutrientName == 'Total lipid (fat)'
      );
      return (
        <>
          {res?.value} {res?.unitName}
        </>
      );
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-3 pe-3 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-3 border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'Energy',
    header: 'Energy',
    cell: ({ row: { original } }) => {
      const nutritionFact = original?.nutritionFact;
      const res = nutritionFact?.find(
        (item: any) => item.nutrientName == 'Energy'
      );
      return (
        <>
          {res?.value} {res?.unitName}
        </>
      );
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-3 pe-3 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-3 border-end border-translucent'
      }
    }
  }
];

const NutritionTable = () => {
  return (
    <div className="border-top border-translucent">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
    </div>
  );
};

export default NutritionTable;
