import StaticPageLayout from 'layouts/StaticPageLayout';
import { Card, Button, Col, Row } from 'react-bootstrap';
const PrivacyPolicy = () => {
  return (
    <StaticPageLayout>
      <h2 className="text-center py-5">Privacy Policy</h2>
      <Card style={{ width: '80%' }}>
        <Card.Body>
          <Card.Title>Effective Date: 16/12/2024</Card.Title>
          <Card.Text>
            <h5>
              We value your privacy and are committed to protecting your
              personal data. This privacy policy outlines how we collect, use,
              store, and share information gathered through our app in
              compliance with Kuwaiti regulations and international best
              practices, including the App Store's requirements.
            </h5>
            <ol>
              <li>
                Data Collection:
                <ul>
                  <li>
                    <strong>Personal Data:</strong> When you use the app, we may
                    collect personal information such as your name, contact
                    details, and dietary preferences.
                  </li>
                  <li>
                    <strong>Food Diary Entries:</strong> The app collects photos
                    and descriptions of your meals for the purpose of analysis
                    and consultation with your nutritionist.
                  </li>
                  <li>
                    <strong>Device Data:</strong> We may collect
                    non-identifiable data such as app usage statistics and
                    device information for performance optimization.
                  </li>
                </ul>
              </li>
              <li>
                Data Use:
                <ul>
                  <li>
                    Your data is used solely for the purpose of providing
                    nutrition analysis, tracking dietary habits, and enabling
                    consultations with your assigned nutritionist.
                  </li>
                  <li>
                    Aggregate, anonymized data may be used for research purposes
                    to improve our services.
                  </li>
                </ul>
              </li>
              <li>
                Data Sharing:
                <ul>
                  <li>
                    Your food diary data is securely transmitted to your
                    nutritionist's database.
                  </li>
                  <li>
                    We do not share your personal information with third parties
                    except as required by law or with your explicit consent.
                  </li>
                </ul>
              </li>
              <li>
                Data Storage and Security:
                <ul>
                  <li>
                    All data is encrypted during transmission and at rest,
                    stored on secure servers compliant with Kuwait’s ePrivacy
                    laws and international standards.
                  </li>
                  <li>
                    Access to your data is restricted to authorized personnel
                    only.
                  </li>
                </ul>
              </li>
              <li>
                User Rights:
                <ul>
                  <li>
                    You have the right to access, update, or delete your
                    personal data at any time.
                  </li>
                  <li>
                    You can withdraw your consent for data processing by
                    contacting our support team.
                  </li>
                </ul>
              </li>
              <li>
                Retention Period:
                <ul>
                  <li>
                    Your personal data is retained for the duration necessary to
                    provide the app’s services or as required by applicable
                    Kuwaiti laws.
                  </li>
                </ul>
              </li>
              <li>
                Cookies and Tracking:
                <ul>
                  <li>
                    The app may use cookies to improve functionality and user
                    experience. You can manage cookie settings through your
                    device preferences.
                  </li>
                </ul>
              </li>
              <li>
                Compliance:
                <ul>
                  <li>
                    This policy complies with Kuwait's personal data protection
                    regulations, including Law No. 20 of 2014 on Electronic
                    Transactions, and meets the requirements for apps
                    distributed via the App Store.
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              For further information or assistance, contact us at [Your Contact
              Information]. By using this app, you consent to the collection and
              use of your data as outlined in this privacy policy.
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
    </StaticPageLayout>
  );
};

export default PrivacyPolicy;
