import Button from 'components/base/Button';
import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as formik from 'formik';
import * as yup from 'yup';
import axios from 'axios';

const ResetPasswordForm = () => {
  const { Formik } = formik;
  const navigate = useNavigate();
  const { token } = useParams();
  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
      ),
    confirmpassword: yup
      .string()
      .required('Confirm Password is required')
      .oneOf([yup.ref('password')], 'Passwords must match')
  });

  const onSubmit = async (values: any) => {
    const formData = values;
    try {
      const data = {
        token: token,
        newPassword: formData.password
      };
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/reset-password`,
        data
      );

      if (response.data['status'] == false) {
        toast.error(response.data['error']['message']);
        return false;
      }

      toast(response.data['data']['message']);
      navigate('/auth/login');
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  return (
    <div className="text-center mb-6">
      <h4 className="text-body-highlight">Reset new password</h4>
      <p className="text-body-tertiary">Type your new password</p>
      <Formik
        validationSchema={schema}
        onSubmit={values => onSubmit(values)}
        initialValues={{
          password: '',
          confirmpassword: ''
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors, dirty }) => (
          <Form noValidate onSubmit={handleSubmit} className="needs-validation">
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="password">New Password</Form.Label>
              <Form.Control
                id="password"
                type="password"
                name="password"
                className="form-icon-input"
                placeholder="New Password"
                value={values.password}
                onChange={handleChange}
                isValid={touched.password && !errors.password}
                isInvalid={touched.password && !!errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {' '}
                {errors.password}{' '}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="confirmpassword">
                Confirm New Password
              </Form.Label>
              <Form.Control
                id="confirmpassword"
                type="password"
                name="confirmpassword"
                className="form-icon-input"
                placeholder="Confirm New Password"
                value={values.confirmpassword}
                onChange={handleChange}
                isValid={touched.confirmpassword && !errors.confirmpassword}
                isInvalid={touched.confirmpassword && !!errors.confirmpassword}
              />
              <Form.Control.Feedback type="invalid">
                {' '}
                {errors.confirmpassword}{' '}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100">
              Set Password
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPasswordForm;
