import axios from 'axios';
import classNames from 'classnames';
import Button from 'components/base/Button';
import * as formik from 'formik';
import { errorHandler } from 'helpers/response-utils';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { goalsValidationSchma } from 'static/Patient';
import * as yup from 'yup';

const UploadGoals = ({ handleCloseEditModal, goalId }: any) => {
  const navigate = useNavigate();
  const { Formik, useFormik } = formik;
  const { id } = useParams(); // Assuming you're using React Router for navigation
  const token = localStorage.getItem('token');

  const [goals, setGoals] = useState({
    date: '',
    weight: '',
    HBA1C: ''
  });
  const [goalsRequestMode, setGoalsRequestMode] = useState('');
  const schema = yup.object().shape(goalsValidationSchma);

  useEffect(() => {
    getGoals();
  }, []);

  const getGoals = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/clinic/goals/patient/${id}/goal/${goalId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        errorHandler(response.data);
        return;
      }
      const data = response.data.data.goals;
      setGoals({
        date: data.date,
        weight: data.weight,
        HBA1C: data.HBA1C
      });
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const onSubmit = async (values: any) => {
    const URL = `${process.env.REACT_APP_ENDPOINT}/clinic/goals/patient/${id}/goal/${goalId}`;
    const header = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const data = {
      date: values.date,
      weight: values.weight,
      HBA1C: values.HBA1C
    };

    try {
      const response = await axios.put(URL, data, header);

      if (response.data.status === false) {
        toast.error(response.data.error.message);
        return;
      }

      toast(response.data.data.message);
      handleCloseEditModal(response.data.data.goals);
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  return (
    <Row>
      <Col xl={12}>
        <Formik
          validationSchema={schema}
          onSubmit={values => onSubmit(values)}
          initialValues={{
            date: '',
            weight: '',
            HBA1C: ''
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            setValues
          }) => {
            useEffect(() => {
              setValues(goals);
            }, [goals]);
            return (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className={classNames('g-3', 'mb-6')}>
                  <Col sm={12} md={12}>
                    <Form.Group className="mb-2">
                      <Form.Label>Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="date"
                        value={values.date}
                        onChange={handleChange}
                        isValid={touched.date && !errors.date}
                        isInvalid={touched.date && !!errors.date}
                      />
                      <Form.Control.Feedback type="invalid">
                        {' '}
                        {errors.date}{' '}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={12}>
                    <Form.Group className="mb-2">
                      <Form.Label>Weight</Form.Label>
                      <Form.Control
                        type="text"
                        name="weight"
                        value={values.weight}
                        onChange={handleChange}
                        isValid={touched.weight && !errors.weight}
                        isInvalid={touched.weight && !!errors.weight}
                      />
                      <Form.Control.Feedback type="invalid">
                        {' '}
                        {errors.weight}{' '}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={12}>
                    <Form.Group className="mb-2">
                      <Form.Label>HBA1C</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="HBA1C"
                        value={values.HBA1C}
                        onChange={handleChange}
                        rows={4}
                        isValid={touched.HBA1C && !errors.HBA1C}
                        isInvalid={touched.HBA1C && !!errors.HBA1C}
                      />
                      <Form.Control.Feedback type="invalid">
                        {' '}
                        {errors.HBA1C}{' '}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <div className="text-end">
                    <Button
                      variant="phoenix-primary"
                      className="me-2 px-6"
                      onClick={() => handleCloseEditModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" variant="primary">
                      Update
                    </Button>
                  </div>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Col>
    </Row>
  );
};

export default UploadGoals;
