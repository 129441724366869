import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import classNames from 'classnames';
import Button from 'components/base/Button';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ForgotPasswordForm = ({
  layout
}: {
  layout?: 'simple' | 'card' | 'split';
}) => {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const [formData, setFormData] = useState({
    email: ''
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/forgot-password`,
        formData
      );

      if (response.data['status'] == false) {
        toast.error(response.data['error']['message']);
        return false;
      }

      toast(response.data['data']['message']);
      navigate('/auth/login');
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  return (
    <div className={classNames({ 'px-xxl-5': !(layout === 'split') })}>
      <div
        className={classNames('text-center', { 'mb-6': !(layout === 'split') })}
      >
        <h4 className="text-body-highlight">Forgot your password?</h4>
        <p className="text-body-tertiary mb-5">
          Enter your email below and we will send <br className="d-sm-none" />
          you a reset link
        </p>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="d-flex align-items-center mb-5"
        >
          <Form.Control
            type="email"
            id="email"
            name="email"
            className="flex-1"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <Button
            type="submit"
            variant="primary"
            className="ms-2"
            endIcon={<FontAwesomeIcon icon={faChevronRight} className="ms-2" />}
          >
            Send
          </Button>
        </Form>
        <Link to="#!" className="fs-9 fw-bold">
          Still having problems?
        </Link>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
