import * as yup from 'yup';

export const patientValidationSchma = {
  name: yup
    .string()
    .min(4, 'Too Short Name!')
    .max(50, 'Too Long Name!')
    .required('Fullname is required!'),
  email: yup.string().email().required('Email address is required!'),
  fileID: yup.string().required('FileId is required!'),
  birthdate: yup.string().required('Birthdate is required!'),
  gender: yup.string().required('Gender is required!')
};

export const patientCreateValidationSchma = {
  name: yup
    .string()
    .min(4, 'Too Short Name!')
    .max(50, 'Too Long Name!')
    .required('Fullname is required!'),
  email: yup.string().email().required('Email address is required!'),
  password: yup.string().required('Password is required!'),
  fileID: yup.string().required('FileId is required!'),
  birthdate: yup.string().required('Birthdate is required!'),
  gender: yup.string().required('Gender is required!')
};

export const goalsValidationSchma = {
  date: yup.string().required('Date is required!'),
  weight: yup.string().required('Weight is required!'),
  HBA1C: yup.string().required('HBA1C is required!')
};
