import axios from 'axios';
import React, { useRef } from 'react';
import classNames from 'classnames';
import Button from 'components/base/Button';
import * as formik from 'formik';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';

const UploadPatientReport = () => {
  const navigate = useNavigate();
  const { Formik, useFormik } = formik;
  const { id } = useParams(); // Assuming you're using React Router for navigation
  const token = localStorage.getItem('token');
  const [reports, setReports] = useState();
  const [show, setShow] = useState('');

  useEffect(() => {
    getReports();
  }, []);

  const getReports = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/clinic/reports/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        toast.error(response.data.error.message);
        return;
      }

      setReports(response.data.data.reports);
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const reportsUploadForm = useFormik({
    initialValues: {
      medical: null,
      inbody: null,
      bloodtest: null,
      other: null
    },
    // validationSchema: yup.object({
    //   medical: yup.mixed().required('A file is required'),
    //   inbody: yup.mixed().required('A file is required'),
    //   bloodtest: yup.mixed().required('A file is required'),
    //   other: yup.mixed().required('A file is required')
    // }),
    onSubmit: async (values: any) => {
      const formData = new FormData();
      formData.append('patientId', '' + id);

      if (values.medical) {
        formData.append('medical', values.medical);
      }

      if (values.inbody) {
        formData.append('inbody', values.inbody);
      }

      if (values.bloodtest) {
        formData.append('bloodtest', values.bloodtest);
      }

      if (values.other) {
        formData.append('other', values.other);
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/clinic/reports`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        if (response.data.status === false) {
          toast.error(response.data.error.message);
          return;
        }

        reportsUploadForm.resetForm();
        resetFileInputs();
        getReports();
        toast(response.data.data.message);
      } catch (error) {
        console.error('Error occurred:', error);
      }
    }
  });

  // Refs for the file input fields
  const medicalInputRef = useRef<HTMLInputElement>(null);
  const inbodyInputRef = useRef<HTMLInputElement>(null);
  const bloodtestInputRef = useRef<HTMLInputElement>(null);
  const otherInputRef = useRef<HTMLInputElement>(null);

  // Function to reset the file input fields
  const resetFileInputs = () => {
    if (medicalInputRef.current) medicalInputRef.current.value = '';
    if (inbodyInputRef.current) inbodyInputRef.current.value = '';
    if (bloodtestInputRef.current) bloodtestInputRef.current.value = '';
    if (otherInputRef.current) otherInputRef.current.value = '';
  };

  const showModal = (modalType: string) => {
    setShow(modalType);
  };

  const handleClose = () => {
    setShow('');
  };

  const handleDeleteReport = async () => {
    if (!reports) {
      return false;
    }

    const reportID = reports[show][0]['id'];

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_ENDPOINT}/clinic/patient/${id}/report/${reportID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        toast.error(response.data.error.message);
        return;
      }
      getReports();
      handleClose();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const navigateBack = () => {
    navigate('/clinic/patient/list');
  };

  return (
    <>
      <Row>
        <Col xl={12}>
          <Form noValidate onSubmit={reportsUploadForm.handleSubmit}>
            <Row className={classNames('g-3', 'mb-6')}>
              <Col sm={6} md={6}>
                <Form.Group controlId="imageUpload" className="mb-3">
                  <Form.Label>Upload Medical Report</Form.Label>
                  <input
                    type="file"
                    name="medical"
                    className="form-control"
                    onChange={event => {
                      if (
                        event.currentTarget.files &&
                        event.currentTarget.files[0]
                      ) {
                        reportsUploadForm.setFieldValue(
                          'medical',
                          event.currentTarget.files[0]
                        );
                      }
                    }}
                    ref={medicalInputRef}
                    onBlur={reportsUploadForm.handleBlur}
                  />
                  {reportsUploadForm.touched.medical &&
                  reportsUploadForm.errors.medical ? (
                    <Form.Control.Feedback type="invalid">
                      {' '}
                      {typeof reportsUploadForm.errors.medical === 'string' &&
                        reportsUploadForm.errors.medical}{' '}
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
              </Col>

              <Col sm={6} md={6}>
                <Form.Group controlId="imageUpload" className="mb-3">
                  <Form.Label>Upload inbody Report</Form.Label>
                  <input
                    type="file"
                    name="inbody"
                    className="form-control"
                    onChange={event => {
                      if (
                        event.currentTarget.files &&
                        event.currentTarget.files[0]
                      ) {
                        reportsUploadForm.setFieldValue(
                          'inbody',
                          event.currentTarget.files[0]
                        );
                      }
                    }}
                    ref={inbodyInputRef}
                    onBlur={reportsUploadForm.handleBlur}
                  />
                  {reportsUploadForm.touched.inbody &&
                  reportsUploadForm.errors.inbody ? (
                    <Form.Control.Feedback type="invalid">
                      {' '}
                      {typeof reportsUploadForm.errors.inbody === 'string' &&
                        reportsUploadForm.errors.inbody}{' '}
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
              </Col>

              <Col sm={6} md={6}>
                <Form.Group controlId="imageUpload" className="mb-3">
                  <Form.Label>Upload Blood Test Report</Form.Label>
                  <input
                    type="file"
                    name="bloodtest"
                    className="form-control"
                    onChange={event => {
                      if (
                        event.currentTarget.files &&
                        event.currentTarget.files[0]
                      ) {
                        reportsUploadForm.setFieldValue(
                          'bloodtest',
                          event.currentTarget.files[0]
                        );
                      }
                    }}
                    ref={bloodtestInputRef}
                    onBlur={reportsUploadForm.handleBlur}
                  />
                  {reportsUploadForm.touched.bloodtest &&
                  reportsUploadForm.errors.bloodtest ? (
                    <Form.Control.Feedback type="invalid">
                      {' '}
                      {typeof reportsUploadForm.errors.bloodtest === 'string' &&
                        reportsUploadForm.errors.bloodtest}{' '}
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
              </Col>

              <Col sm={6} md={6}>
                <Form.Group controlId="imageUpload" className="mb-3">
                  <Form.Label>Upload Other Reports</Form.Label>
                  <input
                    type="file"
                    name="other"
                    className="form-control"
                    onChange={event => {
                      if (
                        event.currentTarget.files &&
                        event.currentTarget.files[0]
                      ) {
                        reportsUploadForm.setFieldValue(
                          'other',
                          event.currentTarget.files[0]
                        );
                      }
                    }}
                    ref={otherInputRef}
                    onBlur={reportsUploadForm.handleBlur}
                  />
                  {reportsUploadForm.touched.other &&
                  reportsUploadForm.errors.other ? (
                    <Form.Control.Feedback type="invalid">
                      {' '}
                      {typeof reportsUploadForm.errors.other === 'string' &&
                        reportsUploadForm.errors.other}{' '}
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
              </Col>

              <div className="text-end">
                <Button
                  variant="phoenix-primary"
                  className="me-2 px-6"
                  onClick={navigateBack}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Update
                </Button>
              </div>
            </Row>
          </Form>
        </Col>

        <Col xl={12}>
          <Row className={classNames('g-3', 'mb-6')}>
            {reports && reports['medical'] ? (
              <Col sm={4} md={4}>
                <Card style={{ width: '20rem' }}>
                  <Card.Body>
                    <Card.Title>Medical Report</Card.Title>
                    <Card.Text>
                      <a href={reports['medical'][0]['reportFile']} download>
                        {JSON.parse(reports['medical'][0]['fileMeta']).name}
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        style={{ cursor: 'pointer' }}
                        onClick={() => showModal('medical')}
                      />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ) : null}
            {reports && reports['inbody'] ? (
              <Col sm={4} md={4}>
                <Card style={{ width: '20rem' }}>
                  <Card.Body>
                    <Card.Title>Inbody Report</Card.Title>
                    <Card.Text>
                      <a href={reports['inbody'][0]['reportFile']} download>
                        {JSON.parse(reports['inbody'][0]['fileMeta']).name}
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        style={{ cursor: 'pointer' }}
                        onClick={() => showModal('inbody')}
                      />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ) : null}
            {reports && reports['bloodtest'] ? (
              <Col sm={4} md={4}>
                <Card style={{ width: '20rem' }}>
                  <Card.Body>
                    <Card.Title>Bloodtest Report</Card.Title>
                    <Card.Text>
                      <a href={reports['bloodtest'][0]['reportFile']} download>
                        {JSON.parse(reports['bloodtest'][0]['fileMeta']).name}
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        style={{ cursor: 'pointer' }}
                        onClick={() => showModal('bloodtest')}
                      />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ) : null}
            {reports && reports['other'] ? (
              <Col sm={4} md={4}>
                <Card style={{ width: '20rem' }}>
                  <Card.Body>
                    <Card.Title>Other Report</Card.Title>
                    <Card.Text>
                      <a href={reports['other'][0]['reportFile']} download>
                        {JSON.parse(reports['other'][0]['fileMeta']).name}
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        style={{ cursor: 'pointer' }}
                        onClick={() => showModal('other')}
                      />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
      <Modal
        show={show != ''}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete record?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDeleteReport}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadPatientReport;
