import { EventClickArg } from '@fullcalendar/core';
import FullCalendar from 'components/base/FullCalendar';
import CalendarProvider, {
  useCalendarContext
} from 'providers/CalendarProvider';
import { HANDLE_SELECT, SET_CALENDAR_STATE } from 'reducers/CalendarReducer';
import './calendar/calendar.css';
import CalendarEventModal from './calendar/CalendarEventModal';
import CalendarHeader from './calendar/CalendarHeader';
import CalendarTop from './calendar/CalendarTop';

import axios from 'axios';
import * as formik from 'formik';
import { errorHandler } from 'helpers/response-utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const PatientDailyProgress = () => {
  return (
    <CalendarProvider>
      <Calendar />
    </CalendarProvider>
  );
};

const Calendar = () => {
  const { calendarApi, calendarDispatch } = useCalendarContext();
  const { Formik, useFormik } = formik;
  const { id } = useParams(); // Assuming you're using React Router for navigation
  const token = localStorage.getItem('token');
  const [meals, setMeals] = useState([]);

  const handleEventClick = (info: EventClickArg) => {
    if (info.event.url) {
      window.open(info.event.url);
      info.jsEvent.preventDefault();
    } else {
      calendarDispatch({
        type: SET_CALENDAR_STATE,
        payload: {
          selectedEvent: info.event
        }
      });
    }
  };

  const getMealByDateRange = async (payload: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/clinic/meal/getall`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            startDate: moment(payload.start).format('YYYY-MM-DD'),
            endDate: moment(payload.end).format('YYYY-MM-DD'),
            patient: id
          }
        }
      );

      if (response.data.status === false) {
        errorHandler(response.data);
        return;
      }
      const data = response.data.data.meals;
      setMeals(data);
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  return (
    <div>
      <CalendarTop />
      <CalendarHeader />
      <div className="mt-6 mb-9">
        <FullCalendar
          height={800}
          select={info => {
            calendarDispatch({
              type: HANDLE_SELECT,
              payload: info
            });
          }}
          events={meals}
          eventStartEditable={false}
          eventClick={handleEventClick}
          datesSet={getMealByDateRange}
        />
      </div>
      <CalendarEventModal />
    </div>
  );
};

export default PatientDailyProgress;
