import { useEffect, useState } from 'react';
import Avatar from 'components/base/Avatar';
import { Card, Dropdown, Form, Nav } from 'react-bootstrap';
import avatar from 'assets/img/team/avatar-rounded.webp';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { parseData } from 'helpers/utils';

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const nav = useNavigate();

  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const [navItems] = useState([
    {
      label: 'My Account',
      icon: 'settings',
      link: `/${user.role}/my-account`
    }
  ]);

  const handleSignOut = () => {
    localStorage.clear();
    nav('/auth/login');
  };

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar src={avatar} size="xl" />
            <h6 className="text-body-emphasis">
              {user.firstname} {user.lastname}
            </h6>
          </div>
          <div className="mb-3 mx-3">
            <Form.Control type="text" value={user.email} size="sm" readOnly />
          </div>
          <div style={{ height: '3rem' }}>
            <Scrollbar>
              <Nav className="nav flex-column mb-2 pb-1">
                {navItems.map(item => (
                  <Nav.Item key={item.label}>
                    <Nav.Link href={item.link} className="px-3">
                      <FeatherIcon
                        icon={item.icon}
                        size={16}
                        className="me-2 text-body"
                      />
                      <span className="text-body-highlight">{item.label}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Scrollbar>
          </div>
        </Card.Body>
        <Card.Footer className="p-0 border-top border-translucent">
          <br />
          <div className="px-3">
            <Link
              to="javascript:void(0)"
              onClick={handleSignOut}
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              Sign out
            </Link>
          </div>
          <div className="my-2 text-center fw-bold fs-10 text-body-quaternary"></div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
