import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState
} from 'react';
import { Navigate } from 'react-router-dom';

interface MainLayoutContextInterface {
  contentClass: string;
  setContentClass: Dispatch<SetStateAction<string>>;
  footerClass: string;
  setFooterClass: Dispatch<SetStateAction<string>>;
}

export const MainLayoutContext = createContext(
  {} as MainLayoutContextInterface
);

const MainLayoutProvider = ({ children }: PropsWithChildren) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated');

  const user = JSON.parse(localStorage.getItem('user') || '{}');

  if (isAuthenticated == null || isAuthenticated == 'false') {
    return <Navigate to="/auth/login" replace />;
  }

  if (
    isAuthenticated == 'true' &&
    !(window.location.href.indexOf(user.role) > -1) &&
    !(window.location.href.indexOf('my-account') > -1)
  ) {
    localStorage.clear();
    return <Navigate to="/auth/login" replace />;
  }

  const [contentClass, setContentClass] = useState('');
  const [footerClass, setFooterClass] = useState('');
  return (
    <MainLayoutContext.Provider
      value={{ contentClass, setContentClass, footerClass, setFooterClass }}
    >
      {children}
    </MainLayoutContext.Provider>
  );
};

export const useMainLayoutContext = () => useContext(MainLayoutContext);

export default MainLayoutProvider;
